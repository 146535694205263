<template>
    <div>
        <modal :show="show" @close="close">
            <h1>Privacy Policy</h1>
           <p><i><span>Last updated: February 15, 2018</span></i></p>
            <br>
            <p><span>This page informs you of the policies we have established regarding the collection, use and disclosure of Personal Information when you use the heydayretirement.com website (the “Service”). Your Personal Information will not be shared except as described in this Privacy Policy.</span></p>
            <p><span>The Service is owned by Midland National Life Insurance Company. Iowa Labs, LLC DBA Heyday Retirement, which is owned by Stratton &amp; Company Retirement Financial Services, operates the Service. Collectively, these entities are referred to as “us”, “we”, and “our” in this Privacy Policy.</span></p>
            <p><span>We use your Personal Information for providing and improving the Service, marketing the Service and other products, and for compliance with regulatory requirements. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at heydayretirement.com.</span></p>
            <br>
            <p><strong>Information Collection And Use</strong></p>
            <br>
            <p><span>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you.&nbsp;Personally&nbsp;identifiable information may&nbsp;include,&nbsp;but is not limited to, your email address, name, phone number, postal address, your use of the Service, and other information ("Personal Information").</span></p>
            <p><span>We collect this information for the purpose of providing the Service, identifying and communicating with you, responding to your requests/inquiries, recommending insurance products, servicing your account, and improving our services.</span></p>
            <br>
            <p><strong>Log Data</strong></p>
            <br>
            <p><span>We may also collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.</span></p>
            <p><span>In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this type of information in order to increase our Service's functionality. These third party service providers have their own separate and distinct privacy policies addressing how they use such information.</span></p>
            <br>
            <p><strong>Behavioral Remarketing</strong></p>
            <br>
            <p><span>Iowa Labs, LLC DBA Heyday Retirement uses remarketing services to advertise on third party web sites to you after you visited our Service. We, and our third party&nbsp;vendors,&nbsp;use cookies to inform, optimize and serve ads based on your past visits to our Service. &nbsp;Remarketing services include, but are not limited to:</span></p>
            <br>
            <p><strong>Google</strong></p>
            <br>
            <p><span>Google AdWords remarketing service is provided by Google Inc.</span></p>
            <p><span>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page:&nbsp;</span><span><a href="http://www.google.com/settings/ads" class="" rel="noopener noreferrer" target="_blank">http://www.google.com/settings/ads</a></span></p>
            <p><span>Google also recommends installing the Google Analytics Opt-out Browser Add-on -<span>&nbsp;</span></span><a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank"><span></span><span>https://tools.google.com/dlpage/gaoptout</span><span>&nbsp;</span></a><span>- for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</span></p>
            <p><span>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:&nbsp;</span><span><a href="http://www.google.com/intl/en/policies/privacy/" rel="noopener noreferrer" target="_blank">http://www.google.com/intl/en/policies/privacy/</a></span></p>
            <br>
            <p><strong>Twitter</strong></p>
            <br>
            <p><span>Twitter remarketing service is provided by Twitter Inc.</span></p>
            <p><span>You can opt-out from Twitter's interest-based ads by following their instructions:&nbsp;</span><span><a href="https://support.twitter.com/articles/20170405" rel="noopener noreferrer" target="_blank">https://support.twitter.com/articles/20170405</a></span></p>
            <p><span>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page:&nbsp;</span><span><a href="https://twitter.com/privacy" rel="noopener noreferrer" target="_blank">https://twitter.com/privacy</a></span></p>
            <br>
            <p><strong>Facebook</strong></p>
            <br>
            <p><span>Facebook remarketing service is provided by Facebook Inc.</span></p>
            <p><span>You can learn more about interest-based advertising from Facebook by visiting this page:&nbsp;</span><span><a href="https://www.facebook.com/help/164968693837950" rel="noopener noreferrer" target="_blank">https://www.facebook.com/help/164968693837950</a></span></p>
            <p><span>To opt-out from Facebook's interest-based ads follow these instructions from Facebook:&nbsp;</span><span><a href="https://www.facebook.com/about/ads/#568137493302217" rel="noopener noreferrer" target="_blank">https://www.facebook.com/about/ads/#568137493302217</a></span></p>
            <p><span>Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA&nbsp;</span><span><a href="http://www.aboutads.info/choices/" rel="noopener noreferrer" target="_blank">http://www.aboutads.info/choices/</a></span><span>, the Digital Advertising Alliance of Canada in Canada&nbsp;</span><span><a href="http://youradchoices.ca/" class="" rel="noopener noreferrer" target="_blank">http://youradchoices.ca/</a></span><span><span>&nbsp;</span>or the European Interactive Digital Advertising Alliance in Europe&nbsp;</span><span><a href="http://www.youronlinechoices.eu/" rel="noopener noreferrer" target="_blank">http://www.youronlinechoices.eu/</a></span><span>, or opt-out using your mobile device settings.</span></p>
            <p><span>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy:&nbsp;</span><span><a href="https://www.facebook.com/privacy/explanation" rel="noopener noreferrer" target="_blank">https://www.facebook.com/privacy/explanation</a></span></p>
            <br>
            <p><strong>Pinterest</strong></p>
            <br>
            <p><span>Pinterest remarketing service is provided by Pinterest Inc.</span></p>
            <p><span>You can opt-out from Pinterest's interest-based ads by enabling the "Do Not Track" functionality of your web browser or by following Pinterest instructions:&nbsp;</span><span><a href="http://help.pinterest.com/en/articles/personalization-and-data" rel="noopener noreferrer" target="_blank">http://help.pinterest.com/en/articles/personalization-and-data</a></span></p>
            <p><span>You can learn more about the privacy practices and policies of Pinterest by visiting their Privacy Policy page:&nbsp;</span><span><a href="https://about.pinterest.com/en/privacy-policy" rel="noopener noreferrer" target="_blank">https://about.pinterest.com/en/privacy-policy</a></span></p>
            <br>
            <p><strong>AdRoll</strong></p>
            <br>
            <p><span>AdRoll remarketing service is provided by Semantic Sugar, Inc.</span></p>
            <p><span>You can opt-out of AdRoll remarketing by visiting this AdRoll Advertising Preferences web page:&nbsp;</span><span><a href="http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false" rel="noopener noreferrer" target="_blank">http://info.evidon.com/pub_info/573?v=1nt=1nw=false</a></span></p>
            <p><span>For more information on the privacy practices of AdRoll, please visit the AdRoll Privacy Policy web page:&nbsp;</span><span><a href="http://www.adroll.com/about/privacy" rel="noopener noreferrer" target="_blank">http://www.adroll.com/about/privacy</a></span></p>
            <br>
            <p><strong>Dstillery</strong></p>
            <br>
            <blockquote type="cite">
                <div dir="ltr">
                    <div><span>Dstillery uses proprietary social targeting technology to deliver relevant ads to consumers. To accomplish this, Dstillery, like many other Internet advertising technologies, uses “cookies.” Cookies are small text files that contain a string of alphanumeric characters.&nbsp;</span></div>
                    <div>&nbsp;</div>
                    <div><span>You can opt-out of&nbsp;Dstillery's cookies and no longer receive targeted&nbsp;Dstillery advertisements here: <span><a href="https://dstillery.com/ad-choices-optout/">https://dstillery.com/ad-choices-optout/</a></span></span></div>
                    <div>&nbsp;</div>
                </div>
            </blockquote>
            <br>
            <p><strong>Perfect Audience</strong></p>
            <br>
            <p><span>Perfect Audience remarketing service is provided by NowSpots Inc.</span></p>
            <p><span>You can opt-out of Perfect Audience remarketing by visiting these pages: Platform Opt-out (</span><span><a href="http://pixel.prfct.co/coo" target="_blank" rel="noopener noreferrer">http://pixel.prfct.co/coo</a></span><span>) and Partner Opt-out (</span><span><a href="http://ib.adnxs.com/optout" rel="noopener noreferrer" target="_blank">http://ib.adnxs.com/optout</a></span><span>).</span></p>
            <p><span>For more information on the privacy practices of Perfect Audience, please visit the Perfect Audience Privacy Policy Opt-out web page:&nbsp;</span><span><a href="https://www.perfectaudience.com/privacy/index.html" rel="noopener noreferrer" target="_blank">https://www.perfectaudience.com/privacy/index.html</a></span></p>
            <br>
            <p><strong>Do Not Track Disclosure</strong></p>
            <br>
            <p><span>We support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</span></p>
            <p><span>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</span></p>
            <br>
            <p><strong>Service Providers</strong></p>
            <br>
            <p><span>We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services and/or to assist us in analyzing how our Service is used.</span></p>
            <p><span>These third parties have access to your Personal Information only to perform specific tasks on our behalf and are obligated not to disclose or use your information for any other purpose.</span></p>
            <br>
            <p><strong>Communications</strong></p>
            <br>
            <p><span>We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</span></p>
            <br>
            <p><strong>Compliance With Laws</strong></p>
            <br>
            <p><span>We will disclose your Personal Information when required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our Service.</span></p>
            <br>
            <p><strong>Business Transaction</strong></p>
            <br>
            <p><span>If we are involved in a merger, acquisition or asset sale, your Personal Information may be transferred as a business asset. In such cases, we will provide notice before your Personal Information is transferred and/or becomes subject to a different Privacy Policy.</span></p>
            <br>
            <p><strong>Security</strong></p>
            <br>
            <p><span>The security of your Personal Information is important to us, and we strive to implement and maintain reasonable, commercially acceptable security procedures and practices appropriate to the nature of the information we store, in order to protect it from unauthorized access, destruction, use, modification, or disclosure.</span></p>
            <p><span>However, please be aware that no method of transmission over the internet, or method of electronic storage is 100% secure and we are unable in any way to guarantee the absolute security of the Personal Information we have collected from you.</span></p>
            <br>
            <p><strong>International Transfer</strong></p>
            <br>
            <p><span>Your information, including Personal Information, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</span></p>
            <p><span>If you are located outside&nbsp;United States&nbsp;and choose to provide information to us, please note that we transfer the information, including Personal Information, to&nbsp;United States&nbsp;and process it there.</span></p>
            <p><span>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</span></p>
            <br>
            <p><strong>Links To Other Sites</strong></p>
            <br>
            <p><span>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit.</span></p>
            <p><span>We have no control&nbsp;over,&nbsp;and assume no responsibility for the content, privacy policies or practices of any third party sites or services, including sites accessible through links on our website.</span></p>
            <br>
            <p><strong>Children's Privacy</strong></p>
            <br>
            <p><span>Only persons age 18 or older have permission to access our Service. Our Service does not address anyone under the age of 13 ("Children").</span></p>
            <p><span>We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you learn that your Children have provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from&nbsp;a children&nbsp;under age 13 without verification of parental consent, we take steps to remove that information from our servers.</span></p>
            <br>
            <p><strong>Changes To This Privacy Policy</strong></p>
            <br>
            <p><span>This Privacy Policy is effective as of February 15, 2018, as amended from time to time,&nbsp;and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</span></p>
            <p><span>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</span></p>
            <p><span>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided&nbsp;us,&nbsp;or by placing a prominent notice on our website. &nbsp;What constitutes a material change will be determined in our sole discretion.</span></p>
            <br>
            <p><strong>Contact Us</strong></p>
            <br>
            <p><span>If you have any questions about this Privacy Policy, please contact us at the number on this page.</span></p>
            <p><span>Disclaimer</span></p>
            <p><i><span>Last updated: February 15, 2018</span></i></p>
            <p><span>The educational content contained on the heydayretirement.com website (the "Service") is for general information purposes only. Customer information is collected for the purpose of providing the Service, identifying and communicating with you, responding to your requests/inquiries, recommending insurance products, servicing your account, and improving our services.</span></p>
            <p><span>Iowa Labs, LLC DBA Heyday Retirement and Midland National Life Insurance Company assume no responsibility for errors or omissions in the contents on the Service.</span></p>
            <p><span>In no event shall we be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. We reserve the right to make additions, deletions, or modification to the contents on the Service at any time, in our sole discretion, without prior notice.</span></p>
            <p><span>We do not warrant that the website is free of viruses or other harmful components.</span></p>
            <p><span>Cookies Policy</span></p>
            <p><i><span>Last updated: February 15, 2018</span></i></p>
            <p><span>Iowa Labs, LLC DBA Heyday Retirement uses cookies on the heydayretirement.com website (the "Service"). &nbsp;The Service is owned by Midland National Life Insurance Company (collectively with Iowa Labs, LLC, “us”, “we”, or “our”). By using the Service, you consent to the use of cookies.</span></p>
            <p><span>Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.</span></p>
            <br>
            <p><strong>What are cookies?</strong></p>
            <br>
            <p><span>Cookies are files with a small amount of data, which may include an anonymous unique identifier. &nbsp;A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.</span></p>
            <p><span>Cookies can be "persistent" or "session" cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser.</span></p>
            <br>
            <p><strong>How we use cookies:</strong></p>
            <br>
            <p><span>When you use and access the Service, we may place cookies files in your web browser.</span></p>
            <p><span>We use cookies for the following purposes:</span></p>
            <p><span>To enable certain functions of the Service</span></p>
            <p><span>To provide analytics</span></p>
            <p><span>To store your preferences</span></p>
            <p><span>To improve our Service</span></p>
            <p><span>We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:</span></p>
            <p><span>Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.</span></p>
            <p><span>Preferences cookies. We may use preferences cookies to remember information that changes the way the Service behaves or looks, such as the "remember me" functionality of a registered user or a user's language preference.</span></p>
            <p><span>Analytics cookies. We may use analytics cookies to track information about how the Service is used so that we can make improvements. We may also use analytics cookies to test new advertisements, pages, features or new functionality of the Service to see how our users react to them.</span></p>
            <br>
            <p><strong>Third-party Cookies</strong></p>
            <br>
            <p><span>In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.</span></p>
            <br>
            <p><strong>What are your choices regarding cookies?</strong></p>
            <br>
            <p><span>If you want to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.</span></p>
            <p><span>Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly. &nbsp;For more information:</span></p>
            <p><span>For the Chrome web browser, please visit this page from Google:&nbsp;</span><span><a href="https://support.google.com/accounts/answer/32050" rel="noopener noreferrer" target="_blank">https://support.google.com/accounts/answer/32050</a></span></p>
            <p><span>For the Internet Explorer web browser, please visit this page from Microsoft:&nbsp;</span><span><a href="http://support.microsoft.com/kb/278835" rel="noopener noreferrer" target="_blank">http://support.microsoft.com/kb/278835</a></span></p>
            <p><span>For the Firefox web browser, please visit this page from Mozilla:&nbsp;</span><span><a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></span></p>
            <p><span>For the Safari web browser, please visit this page from Apple:&nbsp;</span><span><a href="https://support.apple.com/kb/PH21411?locale=en_US" rel="noopener noreferrer" target="_blank">https://support.apple.com/kb/PH21411?locale=en_US</a></span></p>
            <p><span>For any other web browser, please visit your web browser's official web pages.</span></p>
            <br>
            <p><strong>Where can you find more information about cookies?</strong></p>
            <br>
            <p><span>You can learn more about cookies and the following third-party websites:</span></p>
            <p><span>AllAboutCookies:&nbsp;</span><span><a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org/</a></span></p>
            <span id="hs_cos_wrapper_module_14591014898374354" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><span>Network Advertising Initiative:&nbsp;</span><span><a href="http://www.networkadvertising.org/" rel="noopener noreferrer" target="_blank">http://www.networkadvertising.org/</a></span></span>
        </modal>
    </div>
</template>

<script>
    import Modal from '@/components/Modal'
    export default {
        props: ['show'],
        components: { Modal },
        methods: {
            close: function() {
                this.$emit('close')
            }
        }
    }
</script>