
<template>
  <div class="page_content_wrap" >
    <div
      class="state_wrap py-5 my-4"
    >
      <div class="container">
        <div class="state_content_box mt-3" v-if="getUser">
          <h5 class="text-primary mb-3">
            We’re glad you’re here! <span class="font-size-lg">👋</span>
          </h5>
          <h2 class="text-dark mb-4">
            Let's look into the risks that may affect your retirement.
          </h2>
          <form action="" @submit.prevent="submitState()">
            <div class="form-group">
              <label for="full_name" class="text-dark">Select State</label>
              <Select2 v-model="getUser.state" selectionCssClass="select2-results__option--selected" placeholder="Select State" :options="states" :settings="{ }" />
            </div>
            <button type="submit" class="btn btn-primary btn-lg btn-block mt-4 mb-4" :disabled="!getUser.state">CONTINUE <i class="fas fa-arrow-right pl-1"></i></button>
          </form>
          <p class="font-size-sm text-center text-muted m-0 pt-3">
            By clicking “Continue” you agree to our
            <a href="javascript:void(0);" @click.prevent="openModal('policy')">Privacy Policy</a> and <a href="javascript:void(0);"  @click="openModal('terms')">Terms of Service</a>
          </p>
        </div>
        <div class="loader" v-else></div>
      </div>
    </div>
    <terms-modal :show="modal == 'terms'" @close="closeModal()"></terms-modal>
    <policy-modal :show="modal == 'policy'" @close="closeModal()"></policy-modal>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations} from "vuex";
import { STATES } from '@/data/states'

import { createContact } from "@/utils/firebase";
import { STATE_REGION_LOCAL_STORAGE_KEY } from '@/utils/constants'
const stateOptions = Object.keys(STATES).map(state_key => {
    return {
      id: state_key,
      text: STATES[state_key].name
    }
})

import TermsModal from '@/components/TermsModal'
import PolicyModal from '@/components/PolicyModal'

export default {
  name: "Screen2",
  components: {
    TermsModal,
    PolicyModal
  },
  data() {
    return {
      states: stateOptions, 
      modal: null
    };
  },
  computed: {
    ...mapGetters({
      getUser: "getUser",
      getUserId: "getUserId"
    }),
    ...mapGetters("wizard", {
      progress: "getProgress"
    }),
  },
  methods: {
    ...mapActions("wizard", {
         updateProgress: "updateProgress",
    }),
    ...mapMutations("wizard", ["SET_SURVEY"]),
    createTheContact : async (email, state) => {
        await createContact({
            email, state
        })
    },
    async submitState() {
      
      if(this.getUser.state == '') {
          alert('Select State');
          return false;
      }
      localStorage.setItem(STATE_REGION_LOCAL_STORAGE_KEY, this.getUser.state);
      
      await this.createTheContact(this.getUser.email, this.getUser.state);

      let userObj = {
          state: this.getUser.state,
          riskLink: 'risk.surveyBio'
      };
    
      this.updateProgress({ step: 1, data: userObj });

      this.$router.push({ name : 'risk.surveyBio'});
    },
    openModal(name) {
      this.modal = name;
    },
    closeModal() {
      this.modal = null;
    }
  }
};
</script>
<style scoped>
.state_wrap {
 background-image: url('../../assets/images/usa-map.jpg')
}
</style>
