<template>
    <div>
        <modal :show="show" @close="close">
            <h1>Terms of Service</h1>
           <p><span><i>Last updated: February 15, 2018</i></span></p>
            <br>
            <p><span>Please read these Terms of Service ("Terms", "Terms and Conditions") carefully before using the heydayretirement.com website (the "Service"). &nbsp;The Service is owned by Midland National Life Insurance Company and operated by Iowa Labs, LLC DBA Heyday Retirement (collectively "us", "we", or "our").</span></p>
            <p><span>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Service.</span></p>
            <p><span>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the Terms then you do not have permission to access the Service.</span></p>
            <br>
            <p><span><strong>Availability,&nbsp;Errors&nbsp;and Inaccuracies</strong></span></p>
            <br>
            <p><span>We are constantly updating product and service offerings on the Service. We may experience delays in updating information on the Service and in our advertising on other web sites. The information found on the Service may contain errors or inaccuracies and may not be complete or current. </span></p>
            <p><span>We therefore&nbsp;reserve the right to change or update information at any time without prior notice.</span></p>
            <br>
            <p><span><strong>Accounts</strong></span></p>
            <br>
            <p><span>When you create an account with us, you are certifying to us that you are above the age of&nbsp;18,&nbsp;and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.</span></p>
            <p><span>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</span></p>
            <p><span>You may not use as a username the name of another person or entity or that is not lawfully available for use, or a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a&nbsp;username&nbsp;any name that is offensive, vulgar or obscene.</span></p>
            <p><span>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</span></p>
            <br>
            <p><span><strong>Intellectual Property</strong></span></p>
            <br>
            <p><span>The Service and its original content,&nbsp;features&nbsp;and functionality are and will remain the exclusive property of Midland National Life Insurance Company, and Iowa Labs, LLC DBA Heyday Retirement. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without our prior written consent.</span></p>
            <br>
            <p><span><strong>Links To Other Web Sites</strong></span></p>
            <br>
            <p><span>Our Service may contain links to third party web sites or services that are not owned or controlled by us.</span></p>
            <p><span>We have no control&nbsp;over,&nbsp;and assume no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</span></p>
            <p><span>You acknowledge and agree that we are not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services.</span></p>
            <p><span>We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.</span></p>
            <br>
            <p><span><strong>Termination</strong></span></p>
            <br>
            <p><span>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, in our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</span></p>
            <p><span>If you wish to terminate your account, you may simply discontinue using the Service.</span></p>
            <p><span>All provisions of the Terms which by their nature should survive termination shall survive, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</span></p>
            <br>
            <p><span><strong>Indemnification</strong></span></p>
            <br>
            <p><span>You agree to defend, indemnify and hold harmless Midland National Life Insurance Company, Iowa Labs, LLC DBA Heyday Retirement and their licensee and licensors, employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of (i) your use and access of the Service, by you or any person using your account and password; or (ii) a breach of these Terms.</span></p>
            <br>
            <p><span><strong>Limitation Of Liability</strong></span></p>
            <br>
            <p><span>In no event shall Midland National Life Insurance Company, Iowa Labs, LLC DBA Heyday Retirement, nor their directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</span></p>
            <br>
            <p><span><strong>Disclaimer</strong></span></p>
            <br>
            <p><span>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</span></p>
            <p><span>Midland National Life Insurance Company and Iowa Labs, LLC DBA Heyday Retirement their subsidiaries, affiliates, and licensors do not warrant that (i) the Service will function uninterrupted, secure or available at any particular time or location; (ii) any errors or defects will be corrected; (iii) the Service is free of viruses or other harmful components; or (iv) the results of using the Service will meet your requirements.</span></p>
            <br>
            <p><span><strong>Exclusions</strong></span></p>
            <br>
            <p><span>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</span></p>
            <br>
            <p><span><strong>Governing Law</strong></span></p>
            <br>
            <p><span>These Terms shall be governed and construed in accordance with the laws of Iowa, United States, without regard to its conflict of law provisions.</span></p>
            <p><span>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between you and us regarding our&nbsp;Service,&nbsp;and supersede and replace any prior agreements between you and us regarding the Service.</span></p>
            <br>
            <p><span><strong>Changes</strong></span></p>
            <br>
            <p><span>We reserve the right, in our sole discretion, to modify or replace these Terms at any time. If we make any material changes to these Terms, we will notify you either through the email address you have provided&nbsp;us,&nbsp;or by placing a prominent notice on our website. &nbsp;What constitutes a material change will be determined in our sole discretion.</span></p>
            <p><span>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new Terms, you are no longer authorized to use the Service.</span></p>
            <br>
            <p><span><strong>Contact Us</strong></span></p>
            <br>
            <p><span>If you have any questions about these Terms, please contact us at the number on this page.</span></p>
            <br>
            <p><strong><span>About Heyday</span></strong></p>
            <br>
            <p><span>Heyday Retirement is a premier source for comprehensive tools and informative content designed to help retirees build a secure retirement income plan. This plan can be the foundation for living out the retirement of their dreams — a retirement that is truly their Heyday!</span></p>
            <div class="text-up">
                <p><span>For those who arenʼt there yet, retirement is often viewed as a permanent vacation — a time free from a rigid schedule and routine. However, without the right plan in place, many retirees may find it difficult to make the transition.</span></p>
                <p><span>Retirement is a time when it seems all the rules have changed when it comes to how you manage your finances. Heyday exists to help retirees build upon the skills they already have by taking more control over their finances, which can lead to greater peace of mind in retirement.</span></p>
                <p><span>Since 2001, our team has worked with hundreds of retirees, developing income strategies with them to successfully reach their retirement goals while helping reduce risks.</span></p>
                <p><span>Based on this experience, we developed Heyday Retirement to offer a complete set of online tools that allow retirees to create their personalized retirement income plans.</span></p>
            </div>
        </modal>
    </div>
</template>

<script>
    import Modal from '@/components/Modal'
    export default {
        props: ['show'],
        components: { Modal },
        methods: {
            close: function() {
                this.$emit('close')
            }
        }
    }
</script>